const en = {
    common: {
        addNew: "Add new",
        create: "Create",
        close: "Close",
        clear: "Clear",
        reset: "Reset",
        search: "Search",
        update: "Update",
        edit: "Edit",
        cancel: "Cancel",
        delete: "Delete",
        filter: "Filter",
        save: "Save",
        saveChanges: "Save changes",
        tba: "TBA",
        reason: "Reason: {reason}",
        error: "Error"
    },
    login: {
        loginButton: "Click here to login",
        logOut: "Log out",
        enablePopUps: "Please enable pop-ups if permission is asked by browser. Pop-up is required for login."
    },
    home: {
        title: "Home",
        noPermission: "You do not have permission to show these Graphs. Please contact support@routergroup.fi",
        noOfTrips: "Number of trips",
        statusesOfLastXDays: "Statuses of trips driven in last {days} days",
        transportEventErrors: "Transport event errors",
        errorType: "Error type",
        errorCount: "Count",
        latestSalesInvoices: "Latest sales invoices",
        latestPurchaseInvoices: "Latest purchase invoices",
        invoiceNumber: "Invoice number",
        events: "Events"
    },
    validations: {
        title: "Validations"
    },
    pricing: {
        title: "Pricing"
    },
    customers: {
        title: "Customers"
    },
    userManagement: {
        title: "User Management",
        operator: "Operator",
        addCustomer: "Add company",
        headers: {
            name: "Name",
            description: "Description",
            externalId: "External Id",
            businessId: "Business Id"
        },
        dialog: {
            createCustomer: "Create company",
            updateCustomer: "Update company",
            attributeSelectLabel: "Default Car Attributes",
            name: "Name",
            contact: "Contact",
            description: "Description",
            billingInfo: "Billing info",
            address: "Address",
            phone: "Contact phone",
            email: "Contact email",
            businessId: "Business Id",
            operator: "Operator",
            identifier: "Identifier",
            validate: "Validate / Confirm",
            staticAddresses: "Static Addresses"
        },
        noOperatorsError: "You do not have rights to any operator. Please contact support@routergroup.fi"
    },
    customerDetails: {
        addFavoriteAddress: "Add Address",
        operator: "Operator",
        externalId: "Identifier",
        businessId: "Business Id",
        contact: "Contact",
        billingInfo: "Billing info",
        modifyCustomer: "Modify company",
        createRegistrationCode: "Create registration code",
        createDiscountCode: "Create discount code",
        addUser: "Add user",
        userDeleteConfirm: "Are you sure you want to delete this user?",
        usersTitle: "Users",
        favoriteAddressesTitle: "Favorite Addresses",
        headers: {
            name: "Name",
            email: "Email",
            phone: "Phone",
            billing: "Billing",
            taksini: "Taksini user",
            card: "Card"
        },
        userDialog: {
            createUser: "Create user",
            editUser: "Modify user",
            userId: "Identifier",
            firstName: "First name",
            middleName: "Middle name",
            lastName: "Last name",
            costCenter: "Cost center",
            beneficiaryId: "Beneficiary Id",
            email: "Email",
            phone: "Phone",
            allowBilling: "Allow billing (only company)",
            allowTaksini: "Allow Taksini",
            allowTaksiniHint: "Taksini user can not be modified after creation",
            allowCard: "Allow card",
            validate: "Validate / Confirm",
            taksiniUserEditing: "User is Taksini user, editing of some fields is disabled."
        },
        codeDialog: {
            createNewRegistrationCode: "Create Registration -code",
            createNewDiscountCode: "Create Discount -code",
            code: "Code",
            machineGenerateCode: "Machine generates codes",
            userGenerateCode: "User provides code",
            multipleUse: "Multiple use",
            singleUse: "Single use",
            pleaseCopy: "This wont be shown after this dialog. Please copy it.",
            codesAmount: "Codes to generate",
            infiniteUse: "Infinite Use",
            userMaxUseCount: "User Max. Use Count",
            codeMaxUseCount: "Code Max. Use Count",
            discountValue: "Amount",
            codeTypePercentage: "Percentage",
            codeTypeFixed: "Fixed Amount",
            userProvidedCodesLabel: "User Provided Codes (optional)",
            codeGenerationHint: "How many codes to generate?",
            userMaxUseCountHint: "How many times a unique user can use this code?",
            codeMaxUseCountHint: "How many times this code can be used in total?",
            userProvidedCodesHint: "Enter codes you want to create, rest will be generated"
        },
        favoriteAddressHeaders: {
            addressType: "Address Type",
            street: "Street",
            streetNumber: "Street Number",
            city: "City",
            zipcode: "Zip Code",
            country: "Country",
            alias: "Alias",
            driverNotes: "Driver Notes",
            door: "Door"
        }
    },
    invoicing: {
        title: "Payment Management",
        invoicingOptions: "Invoicing options",
        transportEvents: "Transport Events",
        transportEventFilters: "Transport Event Filters",
        transportEventTypes: {
            title: "Transport event types",
            unknown: "Unknown",
            normal: "Normal",
            likuma: {
                title: "Likuma",
                likumaTripInfo: "Likuma trip info",
                likumaCharge: "Likuma charge",
                likumaCancel: "Likuma cancel trip reserve"
            },
            kela: {
                title: "Kela",
                kelaComboTrip: "Kela combination trip",
                kelaTripInfo: "Kela trip info",
                kelaCharge: "Kela charge"
            },
            taxiPlus: {
                title: "TaxiPlus",
                taxiPlusTripInfo: "TaxiPlus trip info",
                taxiPlusCharge: "TaxiPlus charge"
            },
            receipt: {
                title: "Receipt",
                receiptTripInfo: "Receipt trip info",
                receiptCharge: "Receipt payment info",
                receiptComments: "Receipt comments",
                cardTransactionInformation: "Card transaction information",
                cardRehandleTitle: "Card transaction rehandle",
                verifoneEventDetailsTitle: "Verifone event details",
                verifoneEventDuplicatesShow: "Show Verifone event duplicates",
                verifoneEventCanBeHandled: "Verifone event can be handled"
            },
            keusote: {
                title: "Keusote",
                keusoteTripInfo: "Keusote trip info",
                keusoteCharge: "Keusote charge"
            }
        },
        fields: {
            startTime: "Trip start time",
            endTime: "Trip end time",
            distance: "Distance driven",
            carNumber: "Car number",
            serviceNumber: "Service number",
            driverId: "Driver ID",
            orderId: "Order ID",
            routeNumber: "Route ID",
            receiptNumber: "Receipt number",
            taximeterSerialNumber: "Taximeter serial number",
            cardNumber: "Card number",
            baseCost: "Start fare",
            paymentType: "Payment type",
            paymentMethod: "Payment method",
            costCenter: "Cost center",
            vatPercent: "Vat percent",
            cost: "Cost",
            deductible: "Deductible",
            reimbursement: "Reimbursement",
            discount: "Discount",
            routeTotal: "Route total",
            comments: "Comments",
            salesInvoiceMessage: "Information for sales invoice",
            purchaseInvoiceMessage: "Information for purchase invoice"
        },
        changeSearchCriteria: "Change search criteria",
        searchForFailed: "Search for failed",
        toggleColumns: "Toggle columns",
        toggleColumnsTitle: "Select showable columns",
        hideAllColumns: "Hide all columns",
        showAllColumns: "Show all columns",
        resetDefaults: "Reset to defaults",
        dragColumnsHint: "You can reorder columns by dragging them with mouse",
        sentToExternalValidation: "Sent to validation",
        validationFailReason: "Validation fail reason: {failReason}",
        salesInvoiceNumber: "Sales invoice number: {invoiceNumber}",
        salesInvoiceSent: "Sales invoice sent",
        purchaseInvoiceNumber: "Purchase invoice number: {invoiceNumber}",
        purchaseInvoiceSent: "Purchase invoice sent",
        preBookingExtra: "Pre-booking Extra",
        goodsExtra: "Goods Extra",
        airportExtra: "Airport Extra",
        serviceExtra: "Service Extra",
        aidExtra: "Aid Extra",
        handicapExtra: "Handicap Extra",
        stretcherExtra: "Stretcher Extra",
        approachExtra: "Approach Extra",
        otherExtra: "Other Extra",
        downloadCSV: "Download CSV",
        correctTransportEvent: "CORRECT TRANSPORT EVENT",
        createTransportEvent: "Create transport event",
        editTransportEvent: "Edit transport event",
        editModelLabel: "Model",
        editModelHint: "Edit fields that are relevant to selected model.",
        transportEventCannotBeModified: "Transport event cannot be edited in its current state.",
        undoRemove: "Restore event for modifying",
        sendCorrectedEvent: "Send corrected event",
        updateComments: "Update comments",
        addEvent: "Add event",
        deleteEvent: "Delete event",
        setNotToBeInvoiced: "Do not invoice",
        setToBePurchaseInvoiced: "Set to be purchase invoiced",
        eventHasBeenCorrected: "This event has been corrected once and can not be corrected again.",
        makeCorrections: "Make corrections",
        makeCorrectionsInfo: "You can send corrections to invoiced event only once. After that it can not be corrected again.",
        eventInfo: "Other info",
        fares: "Fares",
        extraCharges: "Extra charges",
        waitFees: "Wait fees",
        addNewFee: "Add new fee",
        editFee: "Edit fee",
        feeType: "Fee type",
        feeName: "Fee name",
        addNewFare: "Add new fare",
        editFare: "Edit Fare",
        fareType: "Fare type",
        fareClass: "Fare class",
        kilometerPrice: "Kilometer price",
        kilometers: "Kilometers",
        meters: "Meters",
        time: "Time",
        price: "Price",
        vat: "Vat",
        addFee: "Add fee",
        addFare: "Add fare",
        addNewWaitFee: "Add new wait fee",
        waitFeeType: "Wait fee type",
        hourPrice: "Hour price",
        addWaitFee: "Add wait fee",
        editWaitFee: "Edit wait fee",
        validationStatusLabel: "Validation status",
        searchRange: "Search range",
        searchSingle: "Search single",
        totalReimbursementSum: "Total",
        paymentMethod: {
            title: "Payment method",
            unknown: "Unknown",
            notReceived: "Not received",
            cash: "Cash",
            debitCard: "Debit card",
            creditCard: "Credit card",
            creditDebitCard: "Card (Credit/Debit)",
            debitCreditCard: "Card (Debit/Credit)",
            invoice: "Invoice",
            taxiCard: "Taxi card",
            socialServiceCard: "Social service card",
            app: "App",
            externalPaymentService: "External payment service"
        },
        area: "Area",
        createInvoices: "Create invoices",
        searchEvents: "Search events",
        sales: {
            title: "Sales invoices",
            creationTitle: "Events ready for sales invoice",
            createdInvoices: "Created invoices",
            invoiceEventTypes: "Sales invoice type",
            invoicingStarted: "Sales invoicing started",
            totalSum: "Total sum",
            lastInvoiceCreated: "Last invoice created",
            noInvoiceCreated: "No invoice created",
            sendToSales: "Create invoices",
            sendAllToSales: "Create invoices from all",
            sendSelectedToSales: "Create invoices from selected",
            summary: {
                title: "Summary of sales invoices",
                reimbursementSum: "Total sum of invoices",
                transportEventCount: "Total count of events",
                invoiceCount: "Total count of invoices"
            }
        },
        purchasing: {
            title: "Purchasing invoices",
            creationTitle: "Events ready for purchase invoice",
            createdInvoices: "Created invoices",
            invoiceEventTypes: "Purchase invoice type",
            invoicingStarted: "Purchase invoicing started",
            totalSum: "Total sum",
            lastInvoiceCreated: "Last invoice created",
            noInvoiceCreated: "No invoice created",
            sendToPurchasing: "Create invoice",
            summary: {
                title: "Summary of purchasing invoices",
                reimbursementSum: "Total sum of reimbursements",
                transportEventCount: "Total count of transport events",
                invoiceCount: "Total sum of invoices",
                updated: "Updated"
            }
        },
        batches: {
            title: "Invoicing batches",
            salesTitle: "Sales invoicing batches",
            purchaseTitle: "Purchase invoicing batches",
            cancelBatchInvoices: "Cancel batch invoices",
            showLogMessages: "Show log messages"
        },
        paytrail: {
            title: "Paytrail",
            transactions: "Paytrail Transactions",
            settlementReport: "Paytrail Settlement Report",
            settlementId: "SettlementId",
            search: "Search Transport Event",
            handle: "Handle Transaction",
            createEvents: "Create Transport Events",
            eventCount: "Event count",
            sum: "Total sum",
            createEventsActionButton: "Create events",
            settle: "Set ready for purchase invoicing"
        },
        verifone: {
            title: "Verifone",
            missing: {
                title: "Missing Verifone Events",
                handlingStatus: "Handling status",
                createEvents: "Create events from selected",
                eventCount: "Event count",
                sum: "Total sum",
                createEventsActionButton: "Create events"
            }
        }
    },
    transportEventTable: {
        headers: {
            eventType: "Event type",
            orderId: "Order id",
            routeNumber: "Route number",
            validationStatus: "Validation status",
            salesInvoiceStatus: "Sales invoice status",
            purchaseInvoiceStatus: "Purchase invoice status",
            modified: "Modified",
            modifier: "Modifier",
            customerName: "Customer name",
            costCenter: "Cost center",
            startTime: "Trip started",
            endTime: "Trip ended",
            startingPoint: "Starting point",
            destination: "Destination",
            distance: "Distance (km)",
            duration: "Duration",
            slowDrive: "Slow drive",
            waitingTime: "Waiting time",
            tripType: "Trip type",
            carNumber: "Car number",
            serviceNumber: "Service number",
            driverId: "Driver id",
            shiftNumber: "Shift number",
            taximeterSerialNumber: "Taximeter serial number",
            receiptNumber: "Receipt number",
            paymentMethod: "Payment method",
            paymentType: "Payment type",
            cardNumber: "Card number",
            comments: "Comments",
            vatPercent: "VAT-%",
            cost: "Cost",
            deductible: "Deductible",
            deductibleVat: "Deductible VAT",
            reimbursement: "Reimbursement",
            reimbursementVat: "Reimbursement VAT",
            discountAmount: "Discount amount",
            extras: "Extras",
            waitingCost: "Waiting cost",
            waitingVat: "Waiting cost VAT",
            kela: {
                batchNumber: "Batch number"
            },
            likuma: {
                welfareTripType: "VPL/SHL",
                highTariff: "High tariff",
                certificateId: "Likuma certificate"
            }
        }
    },
    invoicingRules: {
        title: "Invoicing Rules"
    },
    keyValueInput: {
        key: "Key",
        value: "Value"
    },
    validationError: {
        sumTooLarge: "Summa liian suuri"
    },
    vehicles: {
        title: "Vehicles",
        dialogs: {
            integrationSources: "Integration sources",
            vehicleId: "Vehicle ID",
            vehicleNumber: "Vehicle number",
            registrationPlateNumber: "Register plate number",
            status: "Vehicle status",
            twinType: "Twin type",
            contactInformation: "Contact information",
            phoneNumber: "Phone number",
            email: "Email"
        }
    },
    charts: {
        valid: "Valid",
        incomplete: "Incomplete",
        orderMissing: "Order missing",
        receiptMissing: "Receipt missing",
        validationFailed: "Validation failed",
        waitingForInvoicing: "Waiting for invoicing",
        salesInvoiceSent: "Sales invoice sent",
        purchaseInvoiceSent: "Purchase invoice sent",
        readyForExternalValidationKela: "Ready for Kela validation",
        waitingForExternalValidationKela: "Waiting for Kela validation",
        totalCharge: "Total charge"
    }
};

export default en;
