var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "800" },
      on: { input: _vm.onDialogInput },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              attrs: { short: "", dark: "", color: "primary" },
              scopedSlots: _vm._u([
                {
                  key: "extension",
                  fn: function() {
                    return [
                      _c(
                        "v-tabs",
                        {
                          model: {
                            value: _vm.modelTab,
                            callback: function($$v) {
                              _vm.modelTab = $$v
                            },
                            expression: "modelTab"
                          }
                        },
                        [
                          _vm._l(_vm.localInvoiceOptions, function(option) {
                            return _c(
                              "v-tab",
                              {
                                key: option.id,
                                attrs: { href: "#" + option.id }
                              },
                              [_vm._v(" " + _vm._s(option.optionsName) + " ")]
                            )
                          }),
                          _c(
                            "v-tab",
                            { on: { click: _vm.createNewInvoiceOption } },
                            [_vm._v("+ " + _vm._s(_vm.$t("common.addNew")))]
                          )
                        ],
                        2
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-btn",
                { attrs: { icon: "", dark: "" }, on: { click: _vm.cancel } },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-toolbar-title", [
                _vm._v(" " + _vm._s(_vm.$t("common.invoiceOptions")) + " ")
              ])
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticStyle: { color: "black" } },
            [
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.modelTab,
                    callback: function($$v) {
                      _vm.modelTab = $$v
                    },
                    expression: "modelTab"
                  }
                },
                _vm._l(_vm.localInvoiceOptions, function(option) {
                  return _c(
                    "v-tab-item",
                    { key: option.id, attrs: { value: option.id } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          refInFor: true,
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.submitForm($event)
                            }
                          }
                        },
                        _vm._l(option.invoicingOptions, function(opt, key) {
                          return _c(
                            "v-row",
                            { key: key, staticClass: "mx-4" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _vm.getOptionTypeLabel(opt.type) === "String"
                                    ? [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: opt.name,
                                            disabled: !opt.canBeModified
                                          },
                                          model: {
                                            value: opt.value,
                                            callback: function($$v) {
                                              _vm.$set(opt, "value", $$v)
                                            },
                                            expression: "opt.value"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.getOptionTypeLabel(opt.type) === "Number"
                                    ? [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: opt.name,
                                            type: "number",
                                            disabled: !opt.canBeModified
                                          },
                                          model: {
                                            value: opt.value,
                                            callback: function($$v) {
                                              _vm.$set(opt, "value", $$v)
                                            },
                                            expression: "opt.value"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.getOptionTypeLabel(opt.type) === "Decimal"
                                    ? [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: opt.name,
                                            type: "number",
                                            step: "0.01",
                                            disabled: !opt.canBeModified
                                          },
                                          model: {
                                            value: opt.value,
                                            callback: function($$v) {
                                              _vm.$set(opt, "value", $$v)
                                            },
                                            expression: "opt.value"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.getOptionTypeLabel(opt.type) === "Date"
                                    ? [
                                        _c("v-date-picker", {
                                          attrs: {
                                            label: opt.name,
                                            disabled: !opt.canBeModified
                                          },
                                          model: {
                                            value: opt.value,
                                            callback: function($$v) {
                                              _vm.$set(opt, "value", $$v)
                                            },
                                            expression: "opt.value"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.getOptionTypeLabel(opt.type) === "Boolean"
                                    ? [
                                        _c("v-switch", {
                                          attrs: {
                                            label: opt.name,
                                            disabled: !opt.canBeModified
                                          },
                                          model: {
                                            value: _vm.booleanValues[key],
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.booleanValues,
                                                key,
                                                $$v
                                              )
                                            },
                                            expression: "booleanValues[key]"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.getOptionTypeLabel(opt.type) === "Guid"
                                    ? [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: opt.name,
                                            disabled: !opt.canBeModified
                                          },
                                          model: {
                                            value: opt.value,
                                            callback: function($$v) {
                                              _vm.$set(opt, "value", $$v)
                                            },
                                            expression: "opt.value"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm
                                    .getOptionTypeLabel(opt.type)
                                    .startsWith("Dictionary")
                                    ? [
                                        _c("v-subheader", [
                                          _vm._v(_vm._s(opt.name))
                                        ]),
                                        _vm._l(opt.items, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            { key: index },
                                            [
                                              _c("v-text-field", {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "prepend",
                                                      fn: function() {
                                                        return [
                                                          _c("v-chip", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.formatPercentage(
                                                                  item.key
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      },
                                                      proxy: true
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: item.value,
                                                  callback: function($$v) {
                                                    _vm.$set(item, "value", $$v)
                                                  },
                                                  expression: "item.value"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        })
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pb-4" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { color: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v(" " + _vm._s(_vm.$t("common.save")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-4",
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("common.cancel")) + " ")]
              ),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }