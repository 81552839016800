const fi = {
    common: {
        addNew: "Lisää uusi",
        create: "Luo",
        close: "Sulje",
        clear: "Tyhjennä",
        reset: "Nollaa",
        search: "Etsi",
        update: "Päivitä",
        edit: "Muokkaa",
        cancel: "Peruuta",
        delete: "Poista",
        filter: "Suodata",
        save: "Tallenna",
        saveChanges: "Tallenna muutokset",
        tba: "Ominaisuus tulossa",
        reason: "Syy: {reason}",
        error: "Virhe"
    },
    login: {
        loginButton: "Kirjaudu sisään",
        logOut: "Kirjaudu ulos",
        enablePopUps: "Salli pop-up-ikkunoiden näyttö selaimen niin pyytäessä. Pop-up ikkunaa tarvitaan sisäänkirjautumiseen."
    },
    home: {
        title: "Etusivu",
        noPermission: "Sinulla ei ole oikeutta katsella graafeja. Ota yhteyttä support@routergroup.fi",
        noOfTrips: "Matkojen määrä",
        statusesOfLastXDays: "Viimeisten {days} päivän aikana ajettujen matkojen tila",
        transportEventErrors: "Matkojen virheet tyypeittäin",
        errorType: "Virheen tyyppi",
        errorCount: "Määrä",
        latestSalesInvoices: "Viimeisimmät laskut",
        latestPurchaseInvoices: "Viimeisimmät tilitykset",
        invoiceNumber: "Laskunumero",
        events: "Tapahtumia"
    },
    validations: {
        title: "Validaatiot"
    },
    pricing: {
        title: "Hinnoittelu"
    },
    customers: {
        title: "Asiakkaat"
    },
    userManagement: {
        title: "Käyttäjähallinta",
        operator: "Operaattori",
        addCustomer: "Lisää yritys",
        headers: {
            name: "Nimi",
            description: "Kuvaus",
            externalId: "Tunniste",
            businessId: "Y-tunnus"
        },
        dialog: {
            createCustomer: "Luo yritys",
            updateCustomer: "Muokkaa yrityksen tietoja",
            attributeSelectLabel: "Oletus Autotyypit",
            name: "Nimi",
            contact: "Yhteyshenkilö",
            description: "Kuvaus",
            billingInfo: "Laskutustiedot",
            address: "Osoite",
            phone: "Puhelinnumero",
            email: "Sähköposti",
            businessId: "Y-tunnus",
            operator: "Operaattori",
            identifier: "Tunniste",
            validate: "Vahvista",
            staticAddresses: "Kiinteät osoitteet"
        },
        noOperatorsError: "Sinulla ei ole oikeuksia yhteenkään operaattoriin. Ota yhteyttä support@routergroup.fi"
    },
    customerDetails: {
        operator: "Operaattori",
        externalId: "Tunniste",
        businessId: "Y-tunnus",
        contact: "Yhteyshenkilö",
        billingInfo: "Laskutustiedot",
        modifyCustomer: "Muokkaa yritystä",
        createRegistrationCode: "Luo rekisteröintikoodi",
        createDiscountCode: "Luo alennuskoodi",
        addUser: "Lisää käyttäjä",
        userDeleteConfirm: "Haluatko varmasti poistaa tämän käyttäjän?",
        usersTitle: "Käyttäjät",
        favoriteAddressesTitle: "Suosikkiosoitteet",
        headers: {
            name: "Nimi",
            email: "Sähköposti",
            phone: "Puhelinnumero",
            billing: "Laskutus",
            taksini: "Taksini-käyttäjä",
            card: "Kortti"
        },
        userDialog: {
            createUser: "Luo käyttäjä",
            editUser: "Muokkaa käyttäjää",
            userId: "Tunniste",
            firstName: "Etunimi",
            middleName: "Toinen nimi",
            lastName: "Sukunimi",
            costCenter: "Kustannuspaikka",
            beneficiaryId: "Sisäinen tunniste",
            email: "Sähköposti",
            phone: "Puhelinnumero",
            allowBilling: "Salli laskutus (vain yritys)",
            allowTaksini: "Taksini-käyttäjä",
            allowTaksiniHint: "Taksini-käyttäjää ei pysty muokkaamaan luomisen jälkeen",
            allowCard: "Salli kortti",
            validate: "Vahvista",
            taksiniUserEditing: "Käyttäjä on Taksini-käyttäjä, osaa arvoista ei voi muokata."
        },
        codeDialog: {
            createNewRegistrationCode: "Luo rekisteröintikoodi",
            createNewDiscountCode: "Luo alennuskoodi",
            code: "Koodi",
            generateCode: "Generoi koodi",
            machineGenerateCode: "Järjestelmä generoi koodit",
            userGenerateCode: "Käyttäjä antaa koodin",
            multipleUse: "Monikäyttöinen",
            singleUse: "Kertakäyttöinen",
            pleaseCopy: "Koodia ei näytetä tämän dialogin sulkemisen jälkeen. Kopioi se talteen.",
            codesAmount: "Koodien määrä",
            infiniteUse: "Rajaton käyttö",
            userMaxUseCount: "Käyttäjän käyttömäärä",
            codeMaxUseCount: "Koodin käyttömäärä",
            discountValue: "Määrä",
            codeTypePercentage: "Prosentti",
            codeTypeFixed: "Kiinteä summa",
            userProvidedCodesLabel: "Käyttäjän antamat koodit (valinnainen)",
            codeGenerationHint: "Kuinka monta koodia luodaan?",
            userMaxUseCountHint: "Kuinka monta kertaa käyttäjä voi käyttää koodia?",
            codeMaxUseCountHint: "Kuinka monta kertaa tätä koodia voidaan käyttää?",
            userProvidedCodesHint: "Syötä koodit jotka haluat että luodaan"
        },
        favoriteAddressHeaders: {
            addressType: "Tyyppi",
            street: "Katuosoite",
            streetNumber: "Katunumero",
            city: "Kaupunki",
            zipcode: "Postinumero",
            country: "Maa",
            alias: "Alias",
            driverNotes: "Viesti kuljettajalle",
            door: "Ovi"
        }
    },
    invoicing: {
        title: "Maksuhallinta",
        invoicingOptions: "Laskutusasetukset",
        transportEvents: "Matkatapahtumat",
        transportEventFilters: "Matkatapahtumien suodattimet",
        transportEventTypes: {
            title: "Matkatapahtumatyypit",
            unknown: "Unknown",
            normal: "Normal",
            likuma: {
                title: "Likuma",
                likumaTripInfo: "Likuma matkatapahtuma",
                likumaCharge: "Likuma maksutapahtuma",
                likumaCancel: "Likuma katevarauksen peruutus"
            },
            kela: {
                title: "Kela",
                kelaComboTrip: "Kela yhdistelymatka",
                kelaTripInfo: "Kela matkatapahtuma",
                kelaCharge: "Kela maksutapahtuma"
            },
            taxiPlus: {
                title: "TaxiPlus",
                taxiPlusTripInfo: "TaxiPlus matkatapahtuma",
                taxiPlusCharge: "TaxiPlus maksutapahtuma"
            },
            receipt: {
                title: "Kuitti",
                receiptTripInfo: "Matkan tiedot",
                receiptCharge: "Maksun tiedot",
                receiptComments: "Kommentit",
                cardTransactionInformation: "Korttitapahtuma",
                cardRehandleTitle: "Korttitapahtuman uudelleenkäsittely",
                verifoneEventDetailsTitle: "Verifone-tapahtuman tiedot",
                verifoneEventDuplicatesShow: "Näytä duplikaatit",
                verifoneEventCanBeHandled: "Tapahtuma voidaan käsitellä"
            },
            keusote: {
                title: "Keusote",
                keusoteTripInfo: "Keusote matkatapahtuma",
                keusoteCharge: "Keusote maksutapahtuma"
            }
        },
        fields: {
            startTime: "Matkan aloitus",
            endTime: "Matkan lopetus",
            distance: "Matkan pituus",
            carNumber: "Autonumero",
            serviceNumber: "Palvelutunnus",
            driverId: "Kuljettajatunnus",
            orderId: "Tilaustunnus",
            routeNumber: "Reittitunnus",
            receiptNumber: "Kuittinumero",
            taximeterSerialNumber: "Taksamittarin sarjanumero",
            cardNumber: "Korttinumero",
            baseCost: "Perusmaksu",
            paymentType: "Maksun tyyppi",
            paymentMethod: "Maksuväline",
            costCenter: "Kustannuspaikka",
            vatPercent: "Alv%",
            cost: "Hinta",
            deductible: "Omavastuu",
            reimbursement: "Korvaus",
            discount: "Alennus",
            routeTotal: "Reitin hinta",
            comments: "Kommentit",
            salesInvoiceMessage: "Lisätietoa myyntilaskulle",
            purchaseInvoiceMessage: "Lisätietoa tilitykseen"
        },
        changeSearchCriteria: "Muuta hakuehtoja",
        searchForFailed: "Etsi virheellisiä",
        toggleColumns: "Valitse näytettävät arvot",
        toggleColumnsTitle: "Valitse näytettävät arvot",
        hideAllColumns: "Piilota kaikki",
        showAllColumns: "Näytä kaikki",
        resetDefaults: "Palauta oletukset",
        dragColumnsHint: "Voit muuttaa kenttien järjestystä vetämällä hiirellä",
        sentToExternalValidation: "Lähetetty tarkistettavaksi",
        validationFailReason: "Validaatio epäonnistui: {failReason}",
        salesInvoiceNumber: "Myyntilaskunumero: {invoiceNumber}",
        salesInvoiceSent: "Myyntilasku lähetetty",
        purchaseInvoiceNumber: "Ostolaskunumero: {invoiceNumber}",
        purchaseInvoiceSent: "Ostolasku lähetetty",
        preBookingExtra: "Etukäteen varattu",
        goodsExtra: "Tavaralisä",
        airportExtra: "Lentokenttälisä",
        serviceExtra: "Palvelulisä",
        aidExtra: "Avustuslisä",
        handicapExtra: "Invalisä",
        stretcherExtra: "Paarilisä",
        approachExtra: "Luokseajolisä",
        otherExtra: "Muu lisä",
        downloadCSV: "Lataa haetut tapahtumat CSV",
        correctTransportEvent: "OIKAISE MATKATAPAHTUMAA",
        createTransportEvent: "Luo matkatapahtuma",
        editTransportEvent: "Muokkaa matkatapahtumaa",
        editModelLabel: "Malli",
        editModelHint: "Näet vain ne kentät, jotka ovat relevantteja valitulle mallille",
        transportEventCannotBeModified: "Matkatapahtumaa ei voida muokata sen nykyisessä tilassa.",
        undoRemove: "Palauta tapahtuma muokattavaksi",
        sendCorrectedEvent: "Lähetä korjattu tapahtuma",
        updateComments: "Päivitä kommentit",
        addEvent: "Lisää tapahtuma",
        deleteEvent: "Poista tapahtuma",
        setNotToBeInvoiced: "Älä tilitä",
        setToBePurchaseInvoiced: "Vie tilitettäväksi",
        eventHasBeenCorrected: "Tämä tapahtuma on jo korjattu eikä sitä voi korjata uudelleen.",
        makeCorrections: "Tee korjauksia",
        makeCorrectionsInfo: "Korjauksen voi tehdä vain kerran. Korjattua tapahtumaa ei voi enää muokata.",
        eventInfo: "Muut tiedot",
        fares: "Taksat",
        extraCharges: "Lisät",
        waitFees: "Odotusmaksut",
        addNewFee: "Lisää uusi lisä",
        editFee: "Muokkaa lisää",
        feeType: "Lisän tyyppi",
        feeName: "Lisän nimi",
        addNewFare: "Lisää uusi taksa",
        editFare: "Muokkaa taksaa",
        fareType: "Taksatyyppi",
        fareClass: "Taksaluokka",
        kilometerPrice: "Kilometrihinta",
        kilometers: "Kilometrit",
        meters: "Metrit",
        time: "Aika",
        price: "Hinta",
        vat: "Alv",
        addFee: "Lisää lisä",
        addFare: "Lisää taksa",
        addNewWaitFee: "Lisää uusi odotusmaksu",
        waitFeeType: "Odotuslaji",
        hourPrice: "Tuntihinta",
        addWaitFee: "Lisää odotusmaksu",
        ditWaitFee: "Muokkaa odotusmaksua",
        validationStatusLabel: "Validoinnin tila",
        searchRange: "Etsi useita",
        searchSingle: "Etsi tunnisteella",
        totalReimbursementSum: "Yhteensä",
        paymentMethod: {
            title: "Maksutapa",
            unknown: "Tuntematon",
            notReceived: "Ei maksutapaa",
            cash: "Käteinen",
            debitCard: "Pankkikortti (debit)",
            creditCard: "Luottokortti (credit)",
            creditDebitCard: "Maksukortti (Credit/Debit)",
            debitCreditCard: "Maksukortti (Debit/Credit)",
            invoice: "Lasku",
            taxiCard: "Taksikortti",
            socialServiceCard: "Sosiaalipalvelukortti",
            app: "Applikaatio",
            externalPaymentService: "Ulkoinen maksupalvelu"
        },
        area: "Alue",
        createInvoices: "Luo laskut",
        searchEvents: "Etsi tapahtumat",
        sales: {
            title: "Myyntilaskut",
            creationTitle: "Myyntilaskulle valmiit tapahtumat",
            createdInvoices: "Luodut myyntilaskut",
            invoiceEventTypes: "Myyntilaskun tyyppi",
            invoicingStarted: "Myyntilaskujen luonti aloitettu",
            totalSum: "Yhteensä",
            lastInvoiceCreated: "Viimeisin myyntilasku luotu",
            noInvoiceCreated: "Ei luotuja myyntilaskuja",
            sendToSales: "Luo myyntilaskut",
            sendAllToSales: "Luo myyntilaskut kaikista",
            sendSelectedToSales: "Luo myyntilaskut valituista",
            summary: {
                title: "Myyntilaskujen yhteenveto",
                reimbursementSum: "Myyntilaskujen summa yhteensä",
                transportEventCount: "Myyntilaskuille menevien tapahtumien määrä",
                invoiceCount: "Myyntilaskujen määrä yhteensä"
            }
        },
        purchasing: {
            title: "Ostolaskut",
            creationTitle: "Ostolaskulle valmiit tapahtumat",
            createdInvoices: "Luodut ostolaskut",
            invoiceEventTypes: "Ostolaskun tyyppi",
            invoicingStarted: "Ostolausujen luonti aloitettu",
            totalSum: "Yhteensä",
            sendToPurchasing: "Luo ostolasku",
            lastInvoiceCreated: "Viimeisin ostolasku luotu",
            noInvoiceCreated: "Ei luotuja ostolaskuja",
            summary: {
                title: "Tilitettävien tapahtumien yhteenveto",
                reimbursementSum: "Tulevat tilitykset yhteensä",
                transportEventCount: "Tilitettävien tapahtumien määrä",
                invoiceCount: "Ostolaskujen määrä yhteensä",
                updated: "Päivitetty"
            }
        },
        batches: {
            title: "Invoicing batches",
            salesTitle: "Myyntilaskutusajot",
            purchaseTitle: "Ostolaskutusajot",
            cancelBatchInvoices: "Peruuta muodostetut laskut",
            showLogMessages: "Näytä lokiviestit"
        },
        paytrail: {
            title: "Paytrail",
            transactions: "Paytrail-tapahtumat",
            settlementReport: "Paytrail-tilitysraportti",
            settlementId: "Tilitysnumero",
            search: "Etsi tilaus",
            handle: "Käsittele tapahtuma",
            createEvents: "Luo tapahtumat",
            eventCount: "Tapahtumien lukumäärä",
            sum: "Kokonaissumma",
            createEventsActionButton: "Luo tapahtumat",
            settle: "Merkkaa tilitettäväksi"
        },
        verifone: {
            title: "Verifone",
            missing: {
                title: "Puuttuvat Verifone-tapahtumat",
                handlingStatus: "Käsittelytila",
                createEvents: "Luo tapahtumat valituista",
                eventCount: "Tapahtumien määrä",
                sum: "Kokonaissumma",
                createEventsActionButton: "Luo tapahtumat"
            }
        }
    },
    transportEventTable: {
        headers: {
            eventType: "Tapahtuman tyyppi",
            orderId: "Tilaustunnus",
            routeNumber: "Reittitunnus",
            validationStatus: "Validoinnin tila",
            salesInvoiceStatus: "Myyntilaskutuksen tila",
            purchaseInvoiceStatus: "Tilityksen tila",
            modified: "Muokattu",
            modifier: "Muokkaaja",
            customerName: "Asiakkaan nimi",
            costCenter: "Kustannuspaikka",
            startTime: "Matka alkoi",
            endTime: "Matka loppui",
            startingPoint: "Lähtöosoite",
            destination: "Määränpää",
            distance: "Matkan pituus (km)",
            duration: "Matkan kesto",
            slowDrive: "Hidasajo",
            waitingTime: "Odotusaika",
            tripType: "Matkatyyppi",
            carNumber: "Autonumero",
            serviceNumber: "Palvelutunnus",
            driverId: "Kuljettajatunnus",
            shiftNumber: "Vuoronumero",
            taximeterSerialNumber: "Mittarin sarjanumero",
            receiptNumber: "Kuittinumero",
            paymentMethod: "Maksutapa",
            paymentType: "Maksun tyyppi",
            cardNumber: "Korttinumero",
            comments: "Kommentit",
            vatPercent: "ALV-%",
            cost: "Kustannus",
            deductible: "Omavastuu",
            deductibleVat: "Omavastuu ALV",
            reimbursement: "Korvaus",
            reimbursementVat: "Korvaus ALV",
            discountAmount: "Alennuksen määrä",
            extras: "Lisät",
            waitingCost: "Odotusmaksu",
            waitingVat: "Odotusmaksu ALV",
            kela: {
                batchNumber: "Erätunnus"
            },
            likuma: {
                welfareTripType: "VPL/SHL",
                highTariff: "High tariff",
                certificateId: "Likuman certifikaatti"
            }
        }
    },
    invoicingRules: {
        title: "Laskutussäännöt"
    },
    keyValueInput: {
        key: "Avain",
        value: "Arvo"
    },
    validationError: {
        sumTooLarge: "Summa liian suuri"
    },
    vehicles: {
        title: "Ajoneuvot",
        dialogs: {
            integrationSources: "Välitysjärjestelmät",
            vehicleId: "Auton tunniste",
            vehicleNumber: "Auton numero",
            registrationPlateNumber: "Rekisterinumero",
            status: "Tila",
            twinType: "Twin-tyyppi",
            contactInformation: "Yhteystiedot",
            phoneNumber: "Puhelinnumero",
            email: "Sähköposti"
        }
    },
    charts: {
        valid: "Kunnossa",
        incomplete: "Maksu- tai tilaustapahtuma puuttuu",
        orderMissing: "Tilaus puuttuu",
        receiptMissing: "Kuitti puuttuu",
        validationFailed: "Virheellinen",
        waitingForInvoicing: "Odottaa laskutusta",
        salesInvoiceSent: "Myyntilasku tehty",
        purchaseInvoiceSent: "Ostolasku tehty",
        readyForExternalValidationKela: "Valmis lähettäväksi Kelaan",
        waitingForExternalValidationKela: "Odottaa Kelan hyväksyntää",
        totalCharge: "Kokonaissumma"
    }
};

export default fi;
