<template>
    <v-dialog v-model="showDialog" max-width="800" @input="onDialogInput">
        <v-card>
            <v-card-title>
                <span id="headline">{{ mode === "edit" ? $t("common.edit") : $t("common.addNew") }}</span>
            </v-card-title>
            <v-card-text style="color: black;">
                <v-row>
                    <v-col cols="6">
                        <v-select v-model="selectedInvoiceOption" :items="invoiceOptions" item-text="optionsName"
                            item-value="id" :label="$t('invoicing.invoicingOptions')" />
                    </v-col>
                    <v-col cols="6">
                        <v-select v-model="selectedEventsFilter" :items="allTransportEventFilters" item-text="filterName"
                            item-value="rowKey" :label="$t('invoicing.transportEventFilters')" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn @click="mode === 'edit' ? emitSave() : emitCreate()" color="primary">
                    {{ mode === "edit" ? $t("common.save") : $t("common.create") }}
                </v-btn>
                <v-btn @click="$emit('cancel')" class="ml-4">
                    {{ $t("common.cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
    props: {
        show: { type: Boolean, required: true },
        selectedRowItem: { type: Object },
        invoiceOptions: { type: Array },
        allTransportEventFilters: { type: Array },
        mode: { type: String, required: true } // mode = edit/create
    },
    data() {
        return {
            showDialog: this.show,
            selectedInvoiceOption: null,
            selectedInvoiceOptionId: null,
            selectedEventsFilter: null,
            selectedEventsFilterId: null,
            localSelectedRowItem: this.mode === "edit" ? _.cloneDeep(this.selectedRowItem) : this.createEmptyRowItem()
        };
    },
    watch: {
        show(newVal) {
            this.showDialog = newVal;
            this.setSelectedRowItem();
        },
        selectedRowItem: {
            handler: function(val) {
                if (this.mode === "edit") {
                    this.localSelectedRowItem = !val ? {} : _.cloneDeep(val);
                }
            }
        }
    },
    methods: {
        onDialogInput(value) {
            if (value === false) {
                this.$emit("cancel");
            }
        },
        setSelectedRowItem() {
            if (this.mode === "edit") {
                this.selectedInvoiceOptionId = this.selectedRowItem.invoicingOptionsId;
                this.selectedEventsFilterId = this.selectedRowItem.transportEventFilterId;
                this.selectedEventsFilter = this.allTransportEventFilters.find(filter => filter.rowKey === this.selectedEventsFilterId);
                this.selectedInvoiceOption = this.invoiceOptions.find(option => option.id === this.selectedInvoiceOptionId);
            } else {
                this.localSelectedRowItem = this.createEmptyRowItem();
            }
        },
        createEmptyRowItem() {
            return {
                invoicingOptionsId: null,
                invoicingOptionsName: "",
                transportEventFilterId: null,
                transportEventFilterName: ""
            };
        },
        emitSave() {
            this.mapSelectedRowItem();
            this.$emit("save", {
                dialog: "invoiceRowSettingsDialog",
                rowItem: { ...this.localSelectedRowItem }
            });
        },
        emitCreate() {
            this.mapSelectedRowItem();
            this.$emit("create", {
                dialog: "invoiceRowSettingsDialog",
                rowItem: { ...this.localSelectedRowItem }
            });
        },
        mapSelectedRowItem() {
            let selectedOption = this.invoiceOptions.find(option => option.id === this.selectedInvoiceOption);
            if (selectedOption) {
                this.localSelectedRowItem.invoicingOptionsId = selectedOption.id;
                this.localSelectedRowItem.invoicingOptionsName = selectedOption.optionsName;
            } else {
                // console.warn("Selected invoice option not found");
            }

            let selectedFilter = this.allTransportEventFilters.find(filter => filter.rowKey === this.selectedEventsFilter);
            if (selectedFilter) {
                this.localSelectedRowItem.transportEventFilterId = selectedFilter.rowKey;
                this.localSelectedRowItem.transportEventFilterName = selectedFilter.filterName;
            } else {
                // console.warn("Selected events filter not found");
            }
        }
    }
};
</script>
