<template>
    <v-dialog v-model="showDialog" max-width="800" @input="onDialogInput">
        <v-card>
            <v-toolbar short dark color="primary">
                <v-btn icon dark @click="cancel">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ $t("common.invoiceOptions") }}
                </v-toolbar-title>
                <template v-slot:extension>
                    <v-tabs v-model="modelTab">
                        <v-tab v-for="option in localInvoiceOptions" :key="option.id" :href="'#' + option.id">
                            {{ option.optionsName }}
                        </v-tab>
                        <v-tab @click="createNewInvoiceOption">+ {{ $t("common.addNew") }}</v-tab>
                    </v-tabs>
                </template>
            </v-toolbar>
            <v-card-text style="color: black;">
                <v-tabs-items v-model="modelTab">
                    <v-tab-item v-for="option in localInvoiceOptions" :key="option.id" :value="option.id">
                        <v-form ref="form" @submit.prevent="submitForm">
                            <v-row v-for="(opt, key) in option.invoicingOptions" :key="key" class="mx-4">
                                <v-col cols="8">
                                    <template v-if="getOptionTypeLabel(opt.type) === 'String'">
                                        <v-text-field v-model="opt.value" :label="opt.name"
                                            :disabled="!opt.canBeModified"></v-text-field>
                                    </template>
                                    <template v-if="getOptionTypeLabel(opt.type) === 'Number'">
                                        <v-text-field v-model="opt.value" :label="opt.name" type="number"
                                            :disabled="!opt.canBeModified"></v-text-field>
                                    </template>
                                    <template v-if="getOptionTypeLabel(opt.type) === 'Decimal'">
                                        <v-text-field v-model="opt.value" :label="opt.name" type="number" step="0.01"
                                            :disabled="!opt.canBeModified"></v-text-field>
                                    </template>
                                    <template v-if="getOptionTypeLabel(opt.type) === 'Date'">
                                        <v-date-picker v-model="opt.value" :label="opt.name"
                                            :disabled="!opt.canBeModified"></v-date-picker>
                                    </template>
                                    <template v-if="getOptionTypeLabel(opt.type) === 'Boolean'">
                                        <v-switch v-model="booleanValues[key]" :label="opt.name"
                                            :disabled="!opt.canBeModified"></v-switch>
                                    </template>
                                    <template v-if="getOptionTypeLabel(opt.type) === 'Guid'">
                                        <v-text-field v-model="opt.value" :label="opt.name"
                                            :disabled="!opt.canBeModified"></v-text-field>
                                    </template>
                                    <template v-if="getOptionTypeLabel(opt.type).startsWith('Dictionary')">
                                        <v-subheader>{{ opt.name }}</v-subheader>
                                        <div v-for="(item, index) in opt.items" :key="index">
                                            <v-text-field v-model="item.value">
                                                <template v-slot:prepend>
                                                    <v-chip>{{ formatPercentage(item.key) }}</v-chip>
                                                </template>
                                            </v-text-field>
                                        </div>
                                    </template>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-card-actions class="pb-4">
                <v-spacer></v-spacer>
                <v-btn @click="submitForm" color="primary">
                    {{ $t("common.save") }}
                </v-btn>
                <v-btn @click="$emit('cancel')" class="ml-4">
                    {{ $t("common.cancel") }}
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { InvoiceOptionsTypeMixin } from "../../../models/InvoiceOptionsType";
import _ from "lodash";

export default {
    mixins: [InvoiceOptionsTypeMixin],
    props: {
        show: { type: Boolean, required: true },
        invoiceOptions: { type: Array, required: true }
    },
    data() {
        return {
            showDialog: this.show,
            booleanValues: {},
            modelTab: null,
            localInvoiceOptions: _.cloneDeep(this.invoiceOptions)
        };
    },
    watch: {
        show(newVal) {
            this.showDialog = newVal;
            if (newVal) {
                this.localInvoiceOptions = _.cloneDeep(this.invoiceOptions);
                this.initializeValues();
                if (this.localInvoiceOptions.length > 0) {
                    this.modelTab = this.localInvoiceOptions[0].id;
                }
            }
        },
        invoiceOptions: {
            handler(newVal) {
                this.localInvoiceOptions = _.cloneDeep(newVal);
                this.initializeValues();
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        onDialogInput(value) {
            if (value === false) {
                this.$emit("cancel");
            }
        },
        initializeValues() {
            this.booleanValues = {};
            this.invoiceOptions.forEach(option => {
                option.invoicingOptions.forEach((opt, key) => {
                    if (this.getOptionTypeLabel(opt.type) === "Boolean") {
                        this.booleanValues[key] = this.toBoolean(opt.value);
                    } else if (this.getOptionTypeLabel(opt.type).startsWith("Dictionary")) {
                        opt.items = this.parseDictionary(opt.value);
                    }
                });
            });
        },
        parseDictionary(value) {
            const parsed = JSON.parse(value);
            return Object.keys(parsed).map(key => ({ key, value: parsed[key] }));
        },
        formatPercentage(value) {
            return `${(value / 100).toFixed(1)}%`;
        },
        submitForm() {
            this.invoiceOptions.forEach(option => {
                option.invoicingOptions.forEach((opt, key) => {
                    if (this.getOptionTypeLabel(opt.type) === "Boolean") {
                        opt.value = this.fromBoolean(this.booleanValues[key]);
                    } else if (this.getOptionTypeLabel(opt.type).startsWith("Dictionary")) {
                        opt.value = JSON.stringify(opt.items.reduce((acc, item) => {
                            acc[item.key] = item.value;
                            return acc;
                        }, {}));
                    }
                });
            });
            if (this.$refs.form.validate()) {
                this.$emit("save", this.invoiceOptions);
            }
        },
        selectInvoiceOption(option) {
            this.modelTab = option.id;
        },
        createNewInvoiceOption() {
            // open dialog where user can set name and erp system for new option
            // then create new options with default values to that erp system
            // default values can be requested from backend
            const newOption = {
                optionsName: "New Option",
                eRPSystem: "2",
                invoicingOptions: []
            };
            this.localInvoiceOptions.push(newOption);
            this.selectInvoiceOption(newOption);
        },
        cancel() {
            this.$emit("cancel");
        }
    },
    mounted() {
        this.initializeValues();
        // hae default arvot uusille invoiceOptioneille kun komponentti ladataan
        // tulisikohan bäkkäriin tehdä reitti joka palauttaisi kaikille erp systeemeille default arvot?
    }
};
</script>
